var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-collapse',{attrs:{"accordion":_vm.zhankai},model:{value:(_vm.pointname),callback:function ($$v) {_vm.pointname=$$v},expression:"pointname"}},[_c('van-collapse-item',{attrs:{"title":this.title,"name":_vm.child.pointid}},[(_vm.child.video.num)?_c('span',[_c('div',{staticClass:"zytitle fontbold"},[_vm._v("视频资源 "+_vm._s(_vm.child.video.num)+" 个")]),_vm._l((_vm.child.video.zy),function(videoinfo){return _c('div',{key:videoinfo.id,staticClass:"zylist"},[_c('van-row',{on:{"click":function($event){return _vm.toVideoPlayer(
                videoinfo.id,
                videoinfo.zyname,
                videoinfo.videoid,
                videoinfo.weixin,
                videoinfo.rname,
                videoinfo.currenttime,
                videoinfo.cid,
                videoinfo.pid
              )}}},[_c('van-col',{attrs:{"span":"1"}},[(videoinfo.sta == 0)?_c('img',{staticClass:"imgtip",attrs:{"src":require("@/assets/no.png")}}):_vm._e(),(videoinfo.sta == 1)?_c('img',{staticClass:"imgtip",attrs:{"src":require("@/assets/ing.png")}}):_vm._e(),(videoinfo.sta == 2)?_c('img',{staticClass:"imgtip",attrs:{"src":require("@/assets/done.png")}}):_vm._e()]),_c('van-col',{attrs:{"span":"14"}},[_vm._v(" "+_vm._s(videoinfo.rname)),_c('font',{attrs:{"color":"#bbb"}},[_vm._v(" ("),_c('van-icon',{attrs:{"name":"manager"}}),_vm._v(_vm._s(videoinfo.views)+")"),_c('br'),(_vm.playerZyId == videoinfo.id)?_c('font',{staticClass:"ing"},[_c('van-icon',{attrs:{"name":"play"}}),_vm._v("正在播放 ")],1):_vm._e()],1)],1),_c('van-col',{attrs:{"span":"1"}}),_c('van-col',{attrs:{"span":"5"}},[_vm._v(_vm._s(videoinfo.timelength))]),_c('van-col',{attrs:{"span":"3 "}},[_c('van-icon',{attrs:{"name":"play-circle-o"}}),_vm._v(" "+_vm._s(videoinfo.times))],1)],1)],1)})],2):_vm._e(),(_vm.child.audio.num)?_c('span',[_c('div',{staticClass:"zytitle fontbold"},[_vm._v("音频资源"+_vm._s(_vm.child.audio.num)+"个")]),_vm._l((_vm.child.audio.zy),function(audioinfo){return _c('div',{key:audioinfo.id,staticClass:"zylist"},[_c('van-row',{on:{"click":function($event){return _vm.toAudioPlayer(
                audioinfo.id,
                audioinfo.zyname,
                audioinfo.videoid,
                audioinfo.rname,
                audioinfo.currenttime
              )}}},[_c('van-col',{attrs:{"span":"1"}},[(audioinfo.sta == 0)?_c('img',{staticClass:"imgtip",attrs:{"src":require("@/assets/no.png")}}):_vm._e(),(audioinfo.sta == 1)?_c('img',{staticClass:"imgtip",attrs:{"src":require("@/assets/ing.png")}}):_vm._e(),(audioinfo.sta == 3)?_c('img',{staticClass:"imgtip",attrs:{"src":require("@/assets/done.png")}}):_vm._e()]),_c('van-col',{attrs:{"span":"15"}},[_vm._v(" "+_vm._s(audioinfo.rname)),_c('font',{attrs:{"color":"#bbb"}},[_vm._v(" ("),_c('van-icon',{attrs:{"name":"manager"}}),_vm._v(_vm._s(audioinfo.views)+") ")],1)],1),_c('van-col',{attrs:{"span":"5"}},[_vm._v(_vm._s(audioinfo.timelength))]),_c('van-col',{attrs:{"span":"3 "}},[_c('van-icon',{attrs:{"name":"play-circle-o"}}),_vm._v(" "+_vm._s(audioinfo.times))],1)],1)],1)})],2):_vm._e(),(_vm.child.pdf.num)?_c('span',[_c('div',{staticClass:"zytitle fontbold"},[_vm._v("共有文本资源"+_vm._s(_vm.child.pdf.num)+"个")]),_vm._l((_vm.child.pdf.zy),function(pdfinfo){return _c('div',{key:pdfinfo.id,staticClass:"zylist"},[_c('van-row',{on:{"click":function($event){return _vm.toPdfViewer(
                _vm.audioinfo.id,
                _vm.audioinfo.zyname,

                _vm.audioinfo.rname
              )}}},[_c('van-col',{attrs:{"span":"16"}},[_vm._v(_vm._s(pdfinfo.rname)),_c('font',{attrs:{"color":"#bbb"}},[_vm._v(" ("),_c('van-icon',{attrs:{"name":"manager"}}),_vm._v(_vm._s(_vm.videoinfo.views)+") ")],1)],1),_c('van-col',{attrs:{"span":"8"}},[_vm._v(_vm._s(pdfinfo.addtime))])],1)],1)})],2):_vm._e(),(_vm.child.pdf.num + _vm.child.audio.num + _vm.child.video.num < 1)?_c('span',[_c('div',{staticClass:"zytitle"},[_vm._v("该科判暂时没有资源。")])]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }