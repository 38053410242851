<template>
  <div>
    <!-- <NavbarCommon
      :title="this.$store.state.study.point.pname"
      :back="back"
    ></NavbarCommon> -->
    <PointInfo :pointinfo="this.$store.state.study.point"></PointInfo>
  </div>
</template>

<script>
// import NavbarCommon from '@/components/common/NavbarCommon'
import PointInfo from '@/components/course/Pointinfo'
export default ({
  data () {
    return {
      back: 'course'
    }
  },
  components: {
    PointInfo
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },
  mounted () {
    this.uid = localStorage.getItem('uid')
    this.StudyPointid = localStorage.getItem('StudyPointid')
    this.classid = localStorage.getItem('classid')
    this.courseid = localStorage.getItem('StudyCourseId')

    const pointInfo = this.getPointInfolocal
    var pointid = ''
    if (pointInfo.length > 0) {
      pointid = pointInfo.id.toString()
    } else {
      pointid = 0
    }
    if (pointid !== this.StudyPointid) {
      //   localStorage.setItem('pointInfo', '')
      //   this.$store.commit('getPointMutation', '')
      console.log('重新获取科判信息！')
      this.$store.dispatch('getPointDetailAction', {
        uid: this.uid,
        StudyPointid: this.StudyPointid,
        classid: this.classid,
        courseid: this.courseid
      })
    } else {
      this.$store.commit('getPointMutation', pointInfo)
    }
  },
  computed: {
    getPointInfolocal () {
      var pointInfo = localStorage.getItem('pointInfo')
      var pointInfoArr = []
      if (pointInfo !== null) {
        pointInfoArr = JSON.parse(pointInfo)
      }
      return pointInfoArr
    }
  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', true)
  }
})
</script>
