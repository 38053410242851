<template>
  <div>
    <van-collapse v-model="pointname" :accordion="zhankai">
      <van-collapse-item :title="this.title" :name="child.pointid">
        <span v-if="child.video.num">
          <div class="zytitle fontbold">视频资源 {{ child.video.num }} 个</div>
          <div
            class="zylist"
            v-for="videoinfo in child.video.zy"
            :key="videoinfo.id"
          >
            <van-row
              @click="
                toVideoPlayer(
                  videoinfo.id,
                  videoinfo.zyname,
                  videoinfo.videoid,
                  videoinfo.weixin,
                  videoinfo.rname,
                  videoinfo.currenttime,
                  videoinfo.cid,
                  videoinfo.pid
                )
              "
            >
              <!--
              @vanclick="updatevideoplayer"
              :id="videoinfo.id"
              :videoid="videoinfo.videoid"
              :rname="videoinfo.rname"
              -->
              <!-- sta: 0
                timelength: "00:24:10"
                times: 0
                @/assets/pres.png
                videoid: "- -->

              <van-col span="1">
                <img
                  v-if="videoinfo.sta == 0"
                  src="@/assets/no.png"
                  class="imgtip"
                />
                <img
                  v-if="videoinfo.sta == 1"
                  src="@/assets/ing.png"
                  class="imgtip"
                />
                <img
                  v-if="videoinfo.sta == 2"
                  src="@/assets/done.png"
                  class="imgtip"
                />
              </van-col>
              <van-col span="14">
                {{ videoinfo.rname
                }}<font color="#bbb">
                  (<van-icon name="manager" />{{ videoinfo.views }})<br />
                  <font v-if="playerZyId == videoinfo.id" class="ing">
                    <van-icon name="play" />正在播放
                  </font>
                </font>
              </van-col>
              <van-col span="1"> </van-col>
              <van-col span="5">{{ videoinfo.timelength }}</van-col>
              <van-col span="3 ">
                <van-icon name="play-circle-o" />
                {{ videoinfo.times }}</van-col
              >
            </van-row>
          </div>
        </span>
        <span v-if="child.audio.num">
          <div class="zytitle fontbold">音频资源{{ child.audio.num }}个</div>
          <div
            class="zylist"
            v-for="audioinfo in child.audio.zy"
            :key="audioinfo.id"
          >
            <van-row
              @click="
                toAudioPlayer(
                  audioinfo.id,
                  audioinfo.zyname,
                  audioinfo.videoid,
                  audioinfo.rname,
                  audioinfo.currenttime
                )
              "
            >
              <van-col span="1">
                <img
                  v-if="audioinfo.sta == 0"
                  src="@/assets/no.png"
                  class="imgtip"
                />
                <img
                  v-if="audioinfo.sta == 1"
                  src="@/assets/ing.png"
                  class="imgtip"
                />
                <img
                  v-if="audioinfo.sta == 3"
                  src="@/assets/done.png"
                  class="imgtip"
                />
              </van-col>
              <van-col span="15">
                {{ audioinfo.rname
                }}<font color="#bbb">
                  (<van-icon name="manager" />{{ audioinfo.views }})
                </font>
              </van-col>
              <van-col span="5">{{ audioinfo.timelength }}</van-col>
              <van-col span="3 ">
                <van-icon name="play-circle-o" />
                {{ audioinfo.times }}</van-col
              >
            </van-row>
          </div>
        </span>
        <span v-if="child.pdf.num">
          <div class="zytitle fontbold">共有文本资源{{ child.pdf.num }}个</div>
          <div class="zylist" v-for="pdfinfo in child.pdf.zy" :key="pdfinfo.id">
            <van-row
              @click="
                toPdfViewer(
                  audioinfo.id,
                  audioinfo.zyname,

                  audioinfo.rname
                )
              "
            >
              <van-col span="16"
                >{{ pdfinfo.rname
                }}<font color="#bbb">
                  (<van-icon name="manager" />{{ videoinfo.views }})
                </font>
              </van-col>
              <van-col span="8">{{ pdfinfo.addtime }}</van-col>
            </van-row>
          </div>
        </span>
        <span v-if="child.pdf.num + child.audio.num + child.video.num < 1">
          <div class="zytitle">该科判暂时没有资源。</div>
        </span>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>

export default ({
  data () {
    return {
      pointname: '1',
      video: {
        id: '',
        url: '',
        cover: '',
        videoid: '',
        rname: '',
        currenttime: ''

        // this.$emit('vanClick', { id: id, rname: rname, videoid: videoid, url: JSON.stringify(this.$store.state.video.videosrc), currenttime: currenttime })
      },
      zhankai: true,
      uid: ''
    }
  },
  mounted () {
    console.log(this.child)
    console.log(this.playerZyId, 'playerZyId')
    this.uid = localStorage.getItem('uid')
  },
  props: ['child', 'keshu'],
  computed: {
    title: {
      get () {
        return this.tabSpace(this.child.cate_level) + this.child.cate_level_kemu + this.keshu + '：' + this.child.pname
      },
      set () { }
    },
    playerZyId: {
      get () {
        return this.$store.state.video.id
      },
      set () { }
    },
    videoid: {
      get () {
        return this.$store.state.video.id
      }
    }
  },
  methods: {
    tabSpace (level) {
      var tabs = ''
      for (var i = 1; i <= level * 1; i++) {
        tabs = tabs + '~'
      }
      return tabs
    },
    toVideoPlayer (id, url, videoid, weixin, rname, currenttime, cid, pid) {
      this.$store.commit('playervideo', false)
      this.video = {
        id: id,
        url: '',
        cover: '',
        videoid: videoid,
        rname: rname,
        currenttime: currenttime
      }
      localStorage.setItem('video', JSON.stringify(this.video))
      // 更新个人点击次数
      this.$store.dispatch('updateZyStudyTimes', {
        zyid: id,
        uid: this.uid,
        pid: pid,
        cid: cid
      })
      console.log(id, url, videoid, weixin)
      if (weixin > 0) {
        console.log('腾讯视频')
        this.$store.commit('getVideoSrc', JSON.stringify({ url: '', id: '' }))
        console.log(typeof this.$store.state.video, 'typeof this.$store.state.video')
        this.$store.dispatch('getTengXunVideoSrc', {
          url: url,
          id: id,
          uid: this.uid
        })
        // while (this.$store.state.video.id) {
        //   console.log('waiting for url')
        // }
        if (this.$store.state.video.id) {
          console.log(this.$store.state.video.videosrc, 'videosrc')
          this.$emit('vanClick', { id: id, rname: rname, videoid: videoid, url: JSON.stringify(this.$store.state.video.videosrc), currenttime: currenttime })
        } // window.location.href = url
        // this.$router.push(
        //   {
        //     name: 'LinkHome',
        //     params: {
        //       url: url,
        //       rname: rname
        //     }
        //   }
        // )
      } else {
        if ((url !== '-' && url !== '') && videoid === '-') {
          console.log('外部视频链接')
          //   window.location.href = url
          this.$emit('vanClick', { id: id, rname: rname, videoid: videoid, url: JSON.stringify(url), currenttime: currenttime })
          this.$store.commit('getVideoSrc', { url: JSON.stringify(url), id: id })
        } else {
          console.log('阿里云')
          this.$store.commit('getVideoSrc', { url: '', id: '' })
          console.log(typeof this.$store.state.video, 'typeof this.$store.state.video')
          this.$store.dispatch('getVideoSrc', {
            videoid: videoid,
            id: id,
            uid: this.uid
          })
          //   while (!this.$store.state.video.id) {
          //     console.log('waiting for url')
          //     // if () {
          //     //   break
          //     // }
          //   }
          //   window.location.href = this.$store.state.video.videosrc JSON.parse(pointInfo)
          if (this.$store.state.video.id) {
            console.log(this.$store.state.video.videosrc, 'videosrc')
            this.$emit('vanClick', { id: id, rname: rname, videoid: videoid, url: JSON.stringify(this.$store.state.video.videosrc), currenttime: currenttime })
          }
          //   console.log(this.$store.state.video.videosrc, 'videosrc')
          //   this.$emit('vanClick', { id: id, rname: rname, videoid: videoid, url: JSON.stringify(this.$store.state.video.videosrc), currenttime: currenttime })
          //   if (this.$store.state.video.videosrc) {
          //     window.location.href = JSON.parse(this.$store.state.video.videosrc)
          //   }
        }
      }
    },
    // mp3
    toAudioPlayer (id, url, videoid, rname) {
      this.$store.commit('playeraudio', true)
      console.log(id, url, videoid, rname)
    },
    // pdf doc
    toPdfViewer (id, url, rname) {
      console.log(id, url, rname)
    }
  },
  watch: {
    menuTree () {
      this.reFresh = false
      this.$nextTick(() => {
        this.reFresh = true
        this.$forceUpdate()
      })
    },
    videoid () {
      this.reFresh = true
      //   this.updateurl = this.url
      console.log(this.videoid, 'watch videoid')
      const video = JSON.parse(localStorage.getItem('video'))

      this.$emit('vanClick', { id: video.id, rname: video.rname, videoid: video.videoid, url: JSON.stringify(this.$store.state.video.videosrc), currenttime: video.currenttime })

      this.$store.commit('playervideo', true)
      //   console.log(this.url, 'url')
      this.$forceUpdate()
    }

  }
})
</script>
<style lang="scss" scoped>
::v-deep .van-cell__title {
  color: rgb(116, 118, 122);
  background-color: rgb(245, 246, 242);
  font-size: 3.611vw;
  padding-top: 1.389vw;
  padding-bottom: 1.389vw;
  padding-left: 0.08vw;
  text-align: left;
}
.zytitle {
  color: rgb(165, 146, 132);
  font-size: 3.611vw;
  width: 100;
  padding-left: 1.08vw;
  padding-top: 0.833vw;
  padding-bottom: 0.833vw;
  background-color: cornsilk;
}
.zylist {
  color: rgb(165, 146, 132);
  font-size: 3.611vw;
  width: 100;
  padding-left: 0.08vw;
  padding-top: 1.833vw;
  padding-bottom: 1.833vw;
  border-bottom: 0.278vw dotted chocolate;
  text-align: left;
}
.fontbold {
  font-weight: bold;
}
.imgtip {
  padding-top: 0.556vw;
  width: 3.889vw;
}
.ing {
  color: rgb(27, 122, 27);
  font-size: 2.056vw;
}
</style>
