<template>
  <span v-if="this.getPointInfo">
    <img
      v-lazy="this.getPointInfo.pic"
      :src="this.getPointInfo.pic"
      width="100%"
      v-if="!playervideo"
    />
    <VideoPlayer
      v-if="playervideo"
      id="videoplayer"
      :video="this.video"
      style="position: fixed; top: 0; width: 100%; height: auto; z-index: 999"
      :key="this.video.url"
    ></VideoPlayer>
    <div
      v-if="playervideo"
      :style="{ height: this.heightData }"
      style="width: 100%; margin-top: 0"
    ></div>

    <van-tabs v-model="activeName">
      <van-tab title="导读" name="a">
        <div class="pname">
          {{ this.getkemu }}{{ this.getkeshu }}:
          {{ this.getPointInfo.pname }}
          <span class="btn" @click="$router.push('/course')">返回课程</span>
        </div>
        <div class="title">
          {{ this.getPointInfo.xueshi }}学时 / {{ this.getPointInfo.views }}人次
          <span
            v-if="this.getPointInfo.ctype == 1 || this.getPointInfo.ctype == 4"
          >
            /
            <span
              @click="toTest(getPointInfo.id, getPointInfo.pname)"
              style="color: red"
              >试题练习</span
            >
            /
            <span
              @click="toTestinfo(getPointInfo.id, getPointInfo.pname)"
              style="color: red"
              >测试</span
            >
            / <span v-if="this.getPointInfo.done">已达标</span
            ><span v-else>未达标</span>
          </span>
        </div>

        <span
          v-if="this.getPointInfo.ctype == 1 || this.getPointInfo.ctype == 4"
        >
          <div class="title">
            测试 {{ this.getPointInfo.testtimes }} 次 / 平均分：{{
              this.getPointInfo.avg
            }}
            / 最高分：{{ this.getPointInfo.max }} / 最低分：{{
              this.getPointInfo.min
            }}
          </div>
        </span>
        <span v-if="this.getPointInfo.aim != '-'">
          <div class="title">目标</div>
          <div v-html="this.getPointInfo.aim" class="info"></div>
        </span>
        <span v-if="this.getPointInfo.emphases != '-'">
          <div class="title">重点</div>
          <div v-html="this.getPointInfo.emphases" class="info"></div>
        </span>
        <span v-if="pointinfo.difficulty != '-'">
          <div class="title">难点</div>
          <div v-html="this.getPointInfo.difficulty" class="info"></div>
        </span>
        <!-- 栏目 -->
        <span v-if="this.getPointInfo.ptype == 1"> </span>

        <span v-if="this.getPointInfo.ptype == 2"> </span>
        <div v-html="this.getPointInfo.content" class="content"></div>
      </van-tab>
      <van-tab title="资源" name="b">
        <!-- <van-checkbox v-model="checked" ref="checkbox"> </van-checkbox> -->
        <van-collapse v-model="activeNames" accordion>
          <div v-for="chd in this.getPointInfo.child" :key="chd.id">
            <Child :child="chd" :keshu="getkeshu" @vanClick="vanClick"></Child>
          </div>
        </van-collapse>
      </van-tab>
      <!-- <van-tab title="随记" name="c">
         <Ckeditor
          :id="pid"
          :editorData="editorData"
          :editorConfig="editorConfig"
        ></Ckeditor> -->
      <!-- </van-tab>
      <van-tab title="提问" name="d"></van-tab>
      -->
    </van-tabs>
  </span>
  <van-loading v-else size="24px" color="#0094ff" vertical class="margintop"
    >资源加载中...</van-loading
  >
</template>

<script>
// props: ['id', 'editorData', 'editorConfig', 'classtp'],
// import NavbarCommon from '@/components/common/NavbarCommon'
// import Pointinfo1 from '@/components/course/Pointinfo1'
// import Pointinfo2 from '@/components/course/Pointinfo2'
// import Pointinfo3 from '@/components/course/Pointinfo3'
// import Pointinfo4 from '@/components/course/Pointinfo4'
import VideoPlayer from '@/components/video/Video'
// import Ckeditor from '@/components/common/Ckeditor'
import Child from '@/components/course/Child'
import { mapState } from 'vuex'
// import { ref } from 'vue'
export default {
  components: { Child, VideoPlayer },
  props: ['pointinfo'],
  data () {
    return {
      id: '',
      ctype: '',
      kemu: '',
      keshu: '',
      back: 'course',
      //   pointInfo: '',
      activeName: 1,
      activeNames: 1,
      zhankai: false,
      //   checked: true,
      video: {
        id: '',
        url: '',
        pic: '',
        currenttime: 0
      },
      // ckeditor
      pid: this.pointinfo.id,
      editorData: '',
      editorConfig: {
        toolbarStartupExpanded: false,
        toolbar: 'Basic',
        height: 150,
        placeholder: '输入随记内容.....',
        classtp: ''
        // toolbar: {
        //   items: [
        //     'bold',
        //     'italic',
        //     'link',
        //     'imageUpload'
        //   ]
        // }

      },
      //   activeNameList: 1,
      windowWidth: document.documentElement.clientWidth // 实时屏幕宽度
      //   autoHeight: ''
    }
  },
  //   setup () {
  //     const activeName = ref('1')
  //     return { activeName }
  //   },
  computed: {
    ...mapState(['playervideo']),
    heightData: {
      get () {
        // console.log(this.autoHeight, 'autoHeight')
        // if (this.windowWidth <= 320) { return this.windowWidth * 0.21 + 'vw' } else {
        //   return this.windowWidth * 0.2 + 'vw'
        // }
        return this.windowWidth * 0.16 + 'vw'
      },
      set () { }
    },
    getkemu: {
      get () {
        var kemu = ''
        if (this.kemu === undefined) {
          const pointInfo = JSON.parse(localStorage.getItem('pointInfo'))
          console.log(typeof pointInfo)
          console.log(pointInfo.child[0].cate_level_kemu)
          //   this.kemu = this.pointInfo.child
          kemu = pointInfo.child[0].cate_level_kemu
        } else {
          kemu = this.kemu
        }
        return kemu
      }
    },
    getkeshu: {
      get () {
        var keshu = ''
        if (this.kemu === undefined) {
          const pointInfo = JSON.parse(localStorage.getItem('pointInfo'))
          console.log(typeof pointInfo)
          console.log(pointInfo.child[0].cate_level_keshu)
          //   this.kemu = this.pointInfo.child
          keshu = pointInfo.child[0].cate_level_keshu
        } else {
          keshu = this.keshu
        }
        return keshu
      }
    },
    getPointInfo: {
      get () {
        if (this.pointinfo === undefined) {
          //   this.pointinfo = JSON.parse(localStorage.getItem('pointInfo'))
          return this.pointinfo
        } else {
          return JSON.parse(localStorage.getItem('pointInfo'))
        }
      }
    }
  },
  mounted () {
    // this.$forceUpdate()
    // console.log(this.$router, 'back web')
    //   this.windowWidth = document.documentElement.clientWidth // 实时屏幕宽度
    /*
        params: {
          id: id,
          lock: lock,
          ctype: ctype,
          kemu: kemu,
          keshu: keshu,
          firstPoint: firstPoint
        }
        this.$store.commit('playervideo', true)
    */
    // this.$refs.zhankai.toggle(true)

    this.id = this.$route.params.id
    this.ctype = this.$route.params.ctype
    this.kemu = this.$route.params.kemu
    this.keshu = this.$route.params.keshu
    // var firstPoint = this.$route.params.firstPoint
    // console.log(this.kemu, this.keshu, firstPoint, 'kemu,keshu,firstPoint')
    // if (this.kemu === undefined) {
    //   this.pointInfo = JSON.parse(localStorage.getItem('pointInfo'))
    //   console.log(typeof this.pointInfo)
    //   console.log(this.pointInfo.child[0].cate_level_kemu)
    //   //   this.kemu = this.pointInfo.child
    // }
    console.log(this.getkumu, ' getkumu')
    console.log(this.getPointInfo, ' getPointInfo')
    console.log(this.video, ' video')
    // console.log(this.pointInfo, this.getKeMu, 'kemu')
    // this.$store.dispatch('getCategories', {
    //   uid: 1
    // })
    // this.$store.dispatch('getCategoryByCatID', {
    //   catid: 1,
    //   model: 1
    // })
    // this.$store.dispatch('getArticleInfo', {
    //   articleid: 1
    // })
    // this.$store.dispatch('actionartList', {
    //   uid: 1
    // })
    // this.$store.dispatch('joinActionary', {
    //   uid: 1,
    //   actionaryid: 1
    // })
  },

  methods: {
    // getHeight () {
    //   this.autoHeight = (this.windowWidth * 0.75) + 'px'
    // },

    toTest (id, pname) {
      localStorage.setItem('TestPointid', id)
      localStorage.setItem('Testpname', pname)
      this.$router.push('/test')
    },
    toTestinfo (id, pname) {
      localStorage.setItem('TestPointid', id)
      localStorage.setItem('Testpname', pname)
      this.$router.push('/Testinfo')
    },
    vanClick (data) {
      console.log(data, 'data')
      //   this.video.id = data.id
      //   this.video.url = JSON.parse(this.$store.state.video.videosrc)// data.url
      //   this.video.rname = data.rname
      //   this.video.videoid = data.videoid
      //   var videoplayer = document.getElementById('videopler')
      //   console.log(videoplayer)
      //   videoplayer.src = data.src
      //   this.$store.commit('playervideo', true) currenttime
      if (data.url) {
        this.video.id = data.id
        this.video.url = JSON.parse(JSON.parse(data.url))
        this.video.rname = data.rname
        this.video.videoid = data.videoid
        this.video.currenttime = data.currenttime
        this.$store.commit('playervideo', true)
        // window.location.href = JSON.parse(this.$store.state.video.videosrc)
      }
    }
  },
  watch: {
    video () {
      console.log(this.video, 'wacth')
    }
  }
  //   created () {
  //     window.addEventListener('resize', this.getHeight)
  //     this.getHeight()
  //   }
}
</script>

<style lang="scss" scoped>
.pname {
  font-size: 3.611vw;
  padding-left: 2.778vw;
  padding-top: 2.778vw;
}
.content {
  padding: 10.001px;
  font-size: 3.611vw;
  line-height: 6.944vw;
}
.info {
  font-size: 3.333vw;
  color: gray;
  padding-left: 2.778vw;
  line-height: 5.556vw;
}
.title {
  font-size: 3.333vw;
  color: #666;
  padding-left: 2.778vw;
  padding-top: 2.778vw;
}
::v-deep img {
  width: 100%;
}
.btn {
  background-color: #f5ebd0;
  //   display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f0960f;
  padding: 0.833vw 0.556vw;
  font-size: 3.611vw;
}
.margintop {
  margin-top: 5.556vw;
}
</style>
