<template>
  <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
  <div class="videoBg">
    <img src="@/assets/syx.png" width="100%" height="215vw" alt="" />
    <div
      id="video"
      v-if="this.updateurl"
      style="
        position: absolute;
        width: 100%;
        z-indent: 2;
        left: 0;
        top: 0;
        text-align: left;
      "
    >
      <div class="player-container">
        <vue-core-video-player
          :src="this.updateurl"
          :cover="updatepic"
          :autoplay="auplay"
          @loadedmetadata="loaded"
          @play="playFunc($event)"
          @pause="pauseFunc($event)"
          @timeupdate="timeupdate($event)"
          @ended="ended"
          ref="videoPlayer"
        ></vue-core-video-player>
      </div>
    </div>

    <div>
      <!-- <div style="position: relative; width: 100%; height: auto"> -->
      <!-- <img src="@/assets/syx.png" width="100%" height="300px" alt="" /> -->
      <!-- <van-notice-bar left-icon="volume-o" text="" /> -->

      <div
        style="
          position: absolute;
          width: 60%;
          z-indent: 2;
          left: 1%;
          top: 3%;
          text-align: left;
        "
      >
        <div
          class="videoplayertitle"
          v-if="video.rname"
          @click="backtoCourse()"
        >
          <span><img src="@/assets/back2.png" class="imgtip" /></span>
          {{ video.rname }}
        </div>
        <!-- <div class="videoplayertitle" v-else>等待加载视频.....back2.png.</div>$router.back() -->
      </div>
    </div>

    <!-- </div> -->
  </div>
  <!-- </van-pull-refresh> -->
</template>

<script>
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
// import post from '@/assets/syx.png'
// <vue-core-video-player @loadedmetadata="loaded" @play="playFunc" @pause="pauseFunc"
//  src="./videos/your_video.mp4"></vue-core-video-player>
export default ({
  props: ['video'],
  data () {
    return {
      updateurl: this.video.url, // this.$store.state.video.videosrc,
      updatepic: this.video.pic,
      id: this.video.id,
      orgcurrentTime: this.video.currenttime,
      auplay: true,
      interva: '',
      //   isPlay: true,
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/syx.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }
    }
  },
  computed: {
  },
  mounted () {
    // console.log(this.url, 'mounted url')
    console.log(this.updateurl, 'mounted updateurl')
    console.log(this.$refs.videoPlayer, 'videoPlayer')
    console.log(this.video, 'videovideo')

    // this.timeSeek()
    // 每隔5秒  保存一次学习时间
    /*
      CREATE TABLE `study_study`(
`id` int(10) unsigned NOT NULL AUTO_INCREMENT COMMENT '资源id',

`currenttime` INT(10) UNSIGNED NOT NULL DEFAULT '0'  COMMENT '学习进度',
`sta` TINYINT(2) unsigned NOT NULL DEFAULT '0' COMMENT '学习状态：0 未学习 1 开始学习 3 完成',
`times` SMALLINT(7) UNSIGNED NOT NULL DEFAULT '0'COMMENT '学习次数',
PRIMARY KEY (`id`),
KEY `course_id` (`cid`),
KEY `point_id` (`pid`),
KEY `zy_id` (`zyid`),
KEY `user_id` (`uid`)
) ENGINE=MyISAM DEFAULT CHARSET=utf8 AUTO_INCREMENT=1 ;;
    */
    // this.studyModel = {
    //   uid: localStorage.getItem('uid'),
    //   pid: localStorage.getItem('StudyPointid'),
    //   cid: localStorage.getItem('StudyCourseId'),
    //   zyid: 0,
    //   sta: 1,
    //   statimes: 1,
    //   currenttime: this.currentTime
    // }
    // // if (this.$store.state.videoFirstPlay) {
    // this.interva = setInterval(() => {
    //   this.updateStudyTime()
    // }, 5000)
    // }
  },
  watch: {
    menuTree () {
      this.reFresh = false
      this.$nextTick(() => {
        this.reFresh = true
        this.$forceUpdate()
      })
    },
    url () {
      this.reFresh = true
      this.updateurl = this.url
      console.log(this.updateurl, 'updateurl')
      console.log(this.url, 'url')
      this.$forceUpdate()
    },
    pic () {
      this.updatepic = this.pic
    },
    currentTimeprops () {
      this.orgcurrentTime = this.currentTimeprops
    }
  },
  methods: {
    loaded () {
      // your code
    },
    playFunc (e) {
      // your code
      e.target.currentTime = this.orgcurrentTime ? this.orgcurrentTime : 0

      this.studyModel = {
        uid: localStorage.getItem('uid'),
        pid: localStorage.getItem('StudyPointid'),
        cid: localStorage.getItem('StudyCourseId'),
        zyid: this.video.id,
        sta: 1,
        statimes: 1,
        currenttime: this.currentTime
      }
      console.log(this.studyModel, 'this.studyModel')
      // if (this.$store.state.videoFirstPlay) {
      this.interva = setInterval(() => {
        this.updateStudyTime()
      }, 5000)

      console.log(this.interva, 'mounted interva')
    },
    pauseFunc (e) {
      clearInterval(this.interva)
      this.currentTime = Math.floor(e.target.currentTime)
      this.orgcurrentTime = Math.floor(e.target.currentTime)
      // your code
    },
    timeupdate (e) {
      this.currentTime = Math.floor(e.target.currentTime)
      this.$store.commit('upCurrentTimeOfpoint', this.currentTime)
    },
    ended () {
      console.log('ended')
      this.studyModel = {
        uid: localStorage.getItem('uid'),
        pid: localStorage.getItem('StudyPointid'),
        cid: localStorage.getItem('StudyCourseId'),
        zyid: this.video.id,
        sta: 2,
        currenttime: this.currentTime
      }
      console.log(this.studyModel, 'studyModel')
      // 请求更新
      this.$store.dispatch('updateStudyTime', this.studyModel)
    },
    // timeSeek (e) {
    //   e.target.currentTime = 200
    //   console.log(e, 'seek') study.point
    // },
    // timset () {

    // },
    updateStudyTime () {
      //   console.log(this.currentTime)
      this.studyModel = {
        uid: localStorage.getItem('uid'),
        pid: localStorage.getItem('StudyPointid'),
        cid: localStorage.getItem('StudyCourseId'),
        zyid: this.video.id,
        sta: 1,
        times: 1,
        currenttime: this.currentTime
      }
      console.log(this.studyModel, 'studyModel')
      // 请求更新
      this.$store.dispatch('updateStudyTime', this.studyModel)
    },
    backtoCourse () {
      console.log('backtoCourse')
      this.$router.push('/course')
    }
  },
  beforeUnmount () {
    // this.isPlay = false
    console.log('clear')
    this.$store.commit('videoFirstPlay', false)
    this.$store.commit('playervideo', false)
    clearInterval(this.interva)
  },
  beforeDestroy () {
    clearInterval(this.interva)
    // this.src = ''
    // this.$refs.videoPlayer.src = ''
    // this.$refs.videoPlayer.poster = ''
    // this.pic = ''
  }
  //   computed: {
  //     srcUrl: {
  //       get () {
  //         return this.src
  //       },
  //       set () { }
  //     }
  //   }
  //   mounted () {
  //     this.src = ''
  //   }
})
</script>

<style lang="scss" scoped>
.videoBg {
  position: relative;
  width: 100%;
  height: 60.889vw;
  overflow: hidden;
}
#video {
  vertical-align: middle;
}
::v-deep .player-container {
  width: 100%;
}
.getsrc {
  margin: 0 auto;
  width: 100%;
  height: 5.556vw;
  text-align: center;
  color: #999;
  font-size: 3.611vw;
  background-color: #ddd;
}
.videoplayertitle {
  color: rgb(252, 250, 249);
  font-size: 3.333vw;
  text-align: left;
  padding-top: 1.389vw;
  padding-bottom: 1.389vw;
  //   background-color: azure;
  margin-top: -1.389vw;
  margin-bottom: 2.778vw;
}
.imgtip {
  padding-top: 0.556vw;
  width: 3.889vw;
}
</style>
